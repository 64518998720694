import { add, init } from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

const apiKey = 'ecc517361971894342fd1021f06bd38b';

// Amplitude 초기화
(async () => {
  const sessionReplay = sessionReplayPlugin({ sampleRate: 1 }); // 샘플링 비율 설정
  await add(sessionReplay); // Session Replay 플러그인 추가

  init(apiKey, {
    autocapture: {
      elementInteractions: true, // 자동으로 요소 상호작용 추적
    },
  });
})();

import { getClientIP } from '../tools/getClientIP';
import { filteredByKey } from '../tools/getKey';
import { apiURL } from '../tools/url';

export const submitForm = async (serviceKey: string, data: any) => {
  try {
    const clientIP = await getClientIP();
    const postData = {
      serviceKey: serviceKey,
      applyData: data,
      clientIP: clientIP,
    };
    const response = await fetch(`${apiURL}/front/apply/saveApplyData`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return {
      status: 'error',
      message: 'Failed',
    };
  }
};

export const rankedCheck = async (
  serviceKey: string,
  araPageData: any,
  submitData: any,
) => {
  try {
    const rankedForm = araPageData.flat().find(item => item.childs[0].isRanked);
    if (!rankedForm) return true;

    const rankedFormData =
      submitData[`rankedSelect_${rankedForm.childs[0].idx}`];
    const parsedRankedFormData = JSON.parse(rankedFormData);
    const userKey = filteredByKey(submitData);

    const postData = {
      serviceKey: serviceKey,
      araIdx: rankedForm.idx,
      itemIdx: parsedRankedFormData.index,
      userKey: userKey,
    };

    const response = await fetch(`${apiURL}/front/apply/applyRanked`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    });

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return { status: 'error', message: 'Failed' };
  }
};

export const sendApplyEmail = async (
  email: string,
  serviceKey: string,
  serviceName: string,
) => {
  try {
    const postData = {
      email,
      serviceName,
      serviceKey,
    };

    const response = await fetch(`${apiURL}/front/apply/sendApply`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    });

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return { status: 500, message: 'Failed' };
  }
};

export const checkPeriodByDB = async (
  dateInfo: any,
  isRange: boolean,
  isTime: boolean,
  isFirst: boolean,
) => {
  try {
    const postData = {
      dateInfo: dateInfo,
      isRange: isRange,
      isTime: isTime,
      isFirst: isFirst,
    };
    const response = await fetch(`${apiURL}/front/apply/checkPeriod`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return { status: 'fail' };
  }
};

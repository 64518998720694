export const ColorList = [
  'Blue',
  'Green',
  'Yellow',
  'Indigo',
  'Orange',
  'Pink',
];

export const ColorType = {
  Blue: '#4F91FF',
  Green: '#65B168',
  Yellow: '#FFC147',
  Indigo: '#5C6BC0',
  Orange: '#FB982E',
  Pink: '#EF6292',
};

export const BackgroundColorMatrix = {
  Blue: ['#F6F9FF', '#EBF2FF', '#D8E6FF', '#C4DAFF', '#9DC2FF', '#76A9FF'],
  Green: ['#F7FBF6', '#EDF6EE', '#DCEDDD', '#CBE5CC', '#A9D3AB', '#87C289'],
  Yellow: ['#FFFCF6', '#FFF8EA', '#FFF1D6', '#FFEAC1', '#FFDC99', '#FFCF70'],
  Indigo: ['#F8F9FD', '#F0F1F9', '#E1E4F3', '#D2D6ED', '#B4BBE2', '#97A0D6'],
  Orange: ['#FFFAF4', '#FEF3E7', '#FEE8D0', '#FDDCB9', '#FDC68B', '#FCAF5C'],
  Pink: ['#FEF7F9', '#FDEDF2', '#FBDCE6', '#F9CADA', '#F6A8C2', '#F285AA'],
};

export const initColorThema = () => {
  document.documentElement.style.removeProperty('--user-bgcolor');
  document.documentElement.style.removeProperty('--bg-color');
  document.documentElement.style.removeProperty('--user-10');
  document.documentElement.style.removeProperty('--user-50');
  document.documentElement.style.removeProperty('--user-100');
  document.documentElement.style.removeProperty('--user-500');
  document.documentElement.style.removeProperty('--user-600');
};

export const setPrimaryColor = (color: string) => {
  document.documentElement.style.setProperty('--user-500', color);
};

export const setBackgroundColor = (color: string) => {
  document.documentElement.style.setProperty('--user-bgcolor', color);
};

export const setThemaColor = (colorList: string[]) => {
  document.documentElement.style.setProperty('--user-10', colorList[4]);
  document.documentElement.style.setProperty('--user-50', colorList[3]);
  document.documentElement.style.setProperty('--user-100', colorList[2]);
  document.documentElement.style.setProperty('--user-500', colorList[1]);
  document.documentElement.style.setProperty('--user-600', colorList[0]);
};

export const setFontFamily = (fontFamily: string) => {
  document.documentElement.style.setProperty('--user-font', fontFamily);
};

const PeriodWarning = () => {
  return (
    <div className="user_wrap user_end">
      <main>
        <div className="user_container">
          <div className="core">
            <div className="form last_page">
              <p className="title done">이미 종료된 폼이에요.</p>
              <p className="explain active">
                제출 기간이 만료되어 더 이상 응답을 할 수 없어요.
                <br />
                제출 기간을 확인해 주세요.
              </p>
            </div>
          </div>
        </div>
      </main>

      <footer className="user_footer">
        <div className="core">
          <p className="copyright">
            Made with <strong>ARA</strong>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default PeriodWarning;

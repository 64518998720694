import { useEffect, useState } from 'react';
import { apiURL } from '../../../../../tools/url';

class SearchItem {
  NEISName: string;
  HiAddress: string;
}

const PopupSearch = ({ closePopup, setResult }) => {
  useEffect(() => {
    const $body = document.querySelector('body') as HTMLBodyElement;
    const overflow = $body.style.overflow;
    $body.style.overflow = 'hidden';
    return () => {
      $body.style.overflow = overflow;
    };
  }, []);

  const [keyword, setKeyword] = useState(''); // 검색어
  const [searchResult, setSearchResult] = useState([]); // 검색 결과
  const [initSearchData, setInitSearchData] = useState([]);

  const search = async () => {
    try {
      const url = `${apiURL}/front/load/loadHighschool`;
      const response = await fetch(url);
      const data = await response.json();
      setInitSearchData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    search();
  }, []);

  const choiceSchool = (e, item) => {
    setResult(item);
    closePopup(item);
  };

  const filterKeyword = () => {
    const filteredData = initSearchData.filter((item: SearchItem) =>
      item.NEISName.includes(keyword),
    );
    setSearchResult(filteredData);
  };

  return (
    <div className="layer_dim">
      <div className="layer search1">
        <div className="layer_header">
          <p className="title">출신 학교 찾기</p>
        </div>
        <div className="layer_cont">
          <div className="cell_box search2">
            <div className="cell_search1 medium">
              <input
                type="text"
                name="searchHighSchool"
                id="searchHighSchool"
                placeholder="출신 고교를 입력해 주세요."
                value={keyword}
                onChange={e => setKeyword(e.target.value)}
                onKeyUp={filterKeyword}
              />
              <a className="btn_search">출신 학교 검색</a>
            </div>
          </div>
          <p className="result_msg">
            <strong>‘{keyword}’</strong> 검색결과 {searchResult.length}건
          </p>
          {searchResult.length === 0 && (
            <div className="list_search1 no_data">
              <p>
                <strong>‘{keyword}’</strong>에 대한 검색 결과가 없습니다.
              </p>
            </div>
          )}
          {searchResult.length > 0 && (
            <div className="list_search1 scroll">
              <ul>
                {searchResult.map((item: SearchItem, index) => (
                  <li key={index}>
                    <a onClick={e => choiceSchool(e, item)}>
                      <strong>{item.NEISName}</strong>
                      <span>{item.HiAddress}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <a className="close" onClick={closePopup}>
          레이어 창 닫기
        </a>
      </div>
    </div>
  );
};

export default PopupSearch;

import { useRef, useState } from 'react';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { useFormContext } from 'react-hook-form';
import { useSectionContext } from '../../../../contexts/SectionContext';
import classNames from 'classnames';

const scriptURL =
  'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';

const themeObj = {
  bgColor: '#FFFFFF', //바탕 배경색
  searchBgColor: '#FFFFFF', //검색창 배경색
  contentBgColor: '#FFFFFF', //본문 배경색(검색결과,결과없음,첫화면,검색서제스트)
  pageBgColor: '#F9F9FA', //페이지 배경색
  textColor: '#5A5B6A', //기본 글자색
  queryTextColor: '#292A31', //검색창 글자색
  postcodeTextColor: '#000000', //우편번호 글자색
  emphTextColor: '#4F91FF', //강조 글자색
  outlineColor: '#E1E1E3', //테두리
};

interface AddressProps {
  idx: number;
}

const Address = (props: AddressProps) => {
  const { idx } = props;
  const thisBaseAddressName = `baseAddress_${idx}`;
  const thisDetailAddressName = `detailAddress_${idx}`;

  const open = useDaumPostcodePopup(scriptURL);
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useFormContext();
  const { required, disabled, isStatic } = useSectionContext();
  watch(thisBaseAddressName);
  const [isFocus, setIsFocus] = useState(false);

  const handleComplete = data => {
    let fullAddress = data.address;
    let zonecode = data.zonecode;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    setValue(thisBaseAddressName, ` (${zonecode}) ${fullAddress}`, {
      shouldValidate: true,
    });
  };

  const handleClick = () => {
    if (disabled) return;
    open({ onComplete: handleComplete, theme: themeObj });
  };

  const disabledClass = disabled ? ' disabled' : '';
  if (isStatic) {
    return (
      <p className="result">
        {`${getValues(thisBaseAddressName)} ${getValues(
          thisDetailAddressName,
        )}`}
      </p>
    );
  }

  const deleteContents = e => {
    e.preventDefault();
    e.stopPropagation();
    setValue(thisBaseAddressName, '');
  };

  return (
    <div className="cell_box">
      <div
        className={classNames({
          cell_search1: true,
          disabled: disabled,
          error: errors.baseAddress,
        })}
        onClick={handleClick}
      >
        <input
          type="text"
          placeholder="주소를 입력해주세요."
          readOnly
          {...register(thisBaseAddressName, { required: required })}
          disabled={disabled}
        />
        {getValues(thisBaseAddressName) &&
          getValues(thisBaseAddressName).length > 0 && (
            <a className="btn_clear" onClick={deleteContents}>
              내용 지우기
            </a>
          )}
        <a className="btn_search">검색</a>
      </div>
      <div
        className={classNames({
          cell_txt1: true,
          disabled: disabled,
          error: errors.baseAddress,
          focus: isFocus,
        })}
        style={{
          marginTop: '8px',
        }}
      >
        <input
          type="text"
          placeholder="상세주소를 입력해주세요."
          {...register(thisDetailAddressName)}
          disabled={disabled}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
        />
      </div>
    </div>
  );
};

export default Address;

import { useState } from 'react';
import ImageViewer from './ImageViewer';

const Image = ({ className, actionData }) => {
  const { src, alt } = actionData;
  const [selectedImage, setSelectedImage] = useState(null);

  const clickHandler = () => {
    setSelectedImage(src);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <img
        className={className}
        alt={alt}
        src={src}
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          display: 'block',
          margin: 'auto',
          cursor: 'pointer',
        }}
        onClick={clickHandler}
      />
      {selectedImage && (
        <ImageViewer src={selectedImage} onClose={handleClose} />
      )}
    </>
  );
};

export default Image;

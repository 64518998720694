import '../assets/css/atomic/error.css';
import Footer from '../components/shared/atoms/layouts/Footer';
const icon_login = require('../assets/images/icon_login.png');

const ErrorPage = () => {
  const gotoHome = () => {
    window.location.href = 'https://home-ara.apply.kr';
  };

  return (
    <div className="feedback">
      <main>
        <div className="user_container">
          <div className="msg_core">
            <div className="form">
              <div className="img" style={{ backgroundColor: '#fff' }}>
                <img src={icon_login} />
              </div>
              <div className="msg">
                <p className="title">페이지를 찾을 수 없어요.</p>
                <p className="explain">
                  찾으시는 페이지는 존재하지 않아요.
                  <br />
                  URL을 다시 확인하거나 아래 버튼을 클릭해 홈으로 돌아가세요.
                </p>
                <p className="btn">
                  <button className="btn_M st1" onClick={gotoHome}>
                    홈으로 바로가기
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ErrorPage;

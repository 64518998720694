import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const ApplyCheckPhoneNumber = () => {
  const [isFocus, setIsFocus] = useState(false);
  const { register, watch, setValue } = useFormContext();

  const phoneNumber = watch('phoneNumber');

  useEffect(() => {
    if (phoneNumber) {
      const formattedValue = formatPhoneNumber(phoneNumber);
      if (formattedValue !== phoneNumber) {
        setValue('phoneNumber', formattedValue);
      }
    }
  }, [phoneNumber, setValue]);

  // 연락처 마스킹 처리 함수
  const formatPhoneNumber = value => {
    if (!value) return value;
    const number = value.replace(/[^0-9]/g, ''); // 숫자만 추출
    if (number.length < 4) return number;
    if (number.length < 7) return `${number.slice(0, 3)}-${number.slice(3)}`;
    if (number.length < 11)
      return `${number.slice(0, 3)}-${number.slice(3, 6)}-${number.slice(6)}`;
    return `${number.slice(0, 3)}-${number.slice(3, 7)}-${number.slice(7, 11)}`;
  };

  return (
    <div className="form_cell">
      <div className="form_cell_header">
        <p className="title active">연락처</p>
      </div>
      <div className="form_cell_cont">
        <div className="cell_box">
          <div className={classNames({ cell_txt1: true, focus: isFocus })}>
            <input
              type="text"
              {...register('phoneNumber')}
              onFocus={() => setIsFocus(true)}
              onBlur={() => setIsFocus(false)}
              placeholder={`연락처를 입력바랍니다.`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyCheckPhoneNumber;

import { useFormContext } from 'react-hook-form';
import { useRef, useState } from 'react';
import SelectOption from './SelectOption';
import EtcOption from './EtcOption';
import { useSectionContext } from '../../../../contexts/SectionContext';

const CellBoxSelect = props => {
  const {
    name,
    optionList,
    hasEtc,
    isMultiple,
    multipleOption,
    multiLimit,
    isRanked,
    radioLimit,
    rankedCount,
  } = props;

  const { getValues, setValue } = useFormContext();
  const { isStatic } = useSectionContext();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const etcRef = useRef(null);

  const changeHandler = e => {
    const thisValue = getValues(name);
    if (isMultiple) {
      // 복수 선택 갯수제한 로직
      if (multipleOption === 'number' && thisValue.length > multiLimit) {
        const selectedValue = e.target.value;
        const removedSelectedValue = thisValue.filter(
          value => value !== selectedValue,
        );
        setValue(name, removedSelectedValue);
        setErrorMessage(`최대 ${multiLimit}개까지 선택 가능합니다.`);
        return;
      }
    }
    // 기타 선택이 해제되는 때
    // 1. 체크박스에서 기타 선택이 해제 되는 경우
    if (isMultiple) {
      const isEtc = e.target.id.indexOf('etc') > -1;
      if (isEtc) {
        const target = e.target;
        etcCheck(target);
      }
    }
    // 2. 라디오에서 기타 선택이 해제 되는 경우
    if (hasEtc && !isMultiple) {
      // 다른 라디오 버튼이 선택되면 기타 선택이 해제되면서, 다시 기타 텍스트로 전환
      const etcElement = document.getElementById(name + 'etc');
      if (etcElement) {
        etcCheck(etcElement);
      }
    }
    setErrorMessage(null);
  };

  const etcCheck = target => {
    if (etcRef.current === null) return;
    if (!target.checked) {
      (etcRef.current as HTMLElement).innerHTML = '기타(직접입력)';
      (etcRef.current as HTMLElement).contentEditable = 'false';
    } else {
      (etcRef.current as HTMLElement).contentEditable = 'true';
      const range = document.createRange();
      const selection = window.getSelection() as Selection;
      range.selectNodeContents(etcRef.current as HTMLElement);
      selection.removeAllRanges();
      selection.addRange(range);
      (etcRef.current as HTMLElement).focus();
    }
  };

  if (isStatic) {
    let thisValue = getValues(name);
    if (!thisValue) return null;
    if (isMultiple) {
      const parsedValue = thisValue.map(value => JSON.parse(value));
      return (
        <p className="result">
          {parsedValue.map(value => value.option).join(', ')}
        </p>
      );
    } else {
      const parsedValue = JSON.parse(thisValue);
      return <p className="result">{parsedValue.option}</p>;
    }
  }

  return (
    <div className="cell_box">
      {optionList.map((option, index) => (
        <SelectOption
          name={name}
          option={option}
          index={index}
          key={index}
          isMultiple={isMultiple}
          isRanked={isRanked}
          limit={radioLimit ? radioLimit[index] : null}
          rankedCount={rankedCount ? rankedCount[index] : null}
          changeHandler={changeHandler}
        />
      ))}
      {hasEtc && (
        <EtcOption
          name={name}
          isMultiple={isMultiple}
          etcRef={etcRef}
          changeHandler={changeHandler}
        />
      )}
      {errorMessage && errorMessage !== '' && (
        <p className="cell_helper1 error">{errorMessage}</p>
      )}
    </div>
  );
};

export default CellBoxSelect;

import { useEffect, useState } from 'react';
import {
  ColorList,
  ColorType,
  setBackgroundColor,
  setFontFamily,
  setPrimaryColor,
  setThemaColor,
} from '../tools/colorThema';
import { generateBrightnessAdjustedColors } from '../tools/simliarColor';

const fontType = {
  Pretendard: 'Pretendard',
  검은고딕: 'Black Han Sans',
  나눔스퀘어: 'NanumSquare',
  '닉스곤체 2.0': 'NIXGONM-Vb',
  '스포카 한 산스': 'Spoqa Han Sans',
  '에스코어 드림': 'S-CoreDream-3Light',
  '여기어때 잘난체': 'yg-jalnan',
  '카페 24 아네모네 에어': 'Cafe24Oneprettynight',
  'Arita Dotum': 'Arita-dotum-Medium',
  'G마켓 폰트': 'GmarketSansMedium',
  'Noto Sans CJK KR': 'Noto Sans',
};

const useColorThema = configData => {
  const [thema, setThema] = useState('user');
  useEffect(() => {
    if (!configData) return;
    console.log(configData);
    if (ColorList.includes(configData.primaryColor)) {
      setThema(configData.primaryColor.toLowerCase());
      const thisPrimaryColor = ColorType[configData.primaryColor];
      setPrimaryColor(thisPrimaryColor);
    } else {
      setThema('user');
      const similiarColors = generateBrightnessAdjustedColors(
        configData.primaryColor,
      );
      setThemaColor(similiarColors);
    }
    setBackgroundColor(configData.backgroundColor);
    setFontFamily(fontType[configData.font]);
  }, [configData]);

  return thema;
};

export default useColorThema;

import { getClientIP } from '../tools/getClientIP';
import { filteredByKey } from '../tools/getKey';
import { apiURL } from '../tools/url';

export const getCheckApplyIdByKeyValue = async (serviceKey, keyValue) => {
  try {
    const postData = {
      serviceKey,
      keyValue,
    };

    const response = await fetch(`${apiURL}/front/confirm/getCheckApplyId`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    });

    const result = await response.json();
    if (result.status === 'success') {
      return JSON.parse(result.applyId);
    } else return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getApplyDataByApplyId = async (applyKey: string) => {
  try {
    const postData = {
      applyKey,
    };
    const response = await fetch(
      `${apiURL}/front/confirm/getApplyDataByApplyId`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      },
    );
    const result = await response.json();
    if (result.status === 'success') {
      return JSON.parse(result.applyData.data);
    } else return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateApplyData = async (
  serviceKey: string,
  araPageData: any,
  applyId: string,
  originalData: any,
  updateData: any,
) => {
  try {
    const rankedForm = araPageData
      .flat()
      .filter(item => item.childs[0].isRanked);

    if (rankedForm.length > 0) {
      const result = await checkedRankedData(
        serviceKey,
        rankedForm[0],
        originalData,
        updateData,
      );
      if (result.message === 'NotExists') return result;
      if (result.message === 'Update Failed') return result;
    }
    const updateResult = await updateNewData(applyId, updateData);
    return updateResult;
  } catch (error) {
    console.log(error);
    return {
      status: 'error',
      message: 'Update Failed',
    };
  }
};

const checkedRankedData = async (
  serviceKey: string,
  rankedForm: any,
  originalData: any,
  updateData: any,
) => {
  try {
    const rankedFormName = Object.keys(updateData).find(key =>
      key.includes('rankedSelect'),
    );
    if (!rankedFormName)
      return {
        status: 'error',
        message: 'Update Failed',
      };
    const rankedFormData = updateData[rankedFormName];
    const parsedRankedFormData = JSON.parse(rankedFormData);
    const araIdx = rankedForm.idx;
    const newKey = filteredByKey(updateData);
    const originalKey = filteredByKey(originalData);

    const postData = {
      serviceKey,
      araIdx: araIdx,
      itemIdx: parsedRankedFormData.index,
      newKey,
      originalKey,
    };

    const result = await fetch(`${apiURL}/front/confirm/checkRankedData`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    });

    const resultData = await result.json();
    return resultData;
  } catch (error) {
    console.log(error);
    return {
      status: 'error',
      message: 'Update Failed',
    };
  }
};

const updateNewData = async (applyId: string, updateData: any) => {
  try {
    const clientIP = await getClientIP();
    const postData = {
      applyId,
      updateData,
      clientIP,
    };

    const result = await fetch(`${apiURL}/front/confirm/updateNewData`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    });

    const resultData = await result.json();
    return resultData;
  } catch (error) {
    console.log(error);
    return {
      status: 'error',
      message: 'Update Failed',
    };
  }
};

import '../../../../assets/css/atomic/loading.css';
import { createPortal } from 'react-dom';

const LoadingSpinner = () => {
  return (
    <div className="loading-container">
      <div className="loader"></div>
    </div>
  );
};

const LoadingPortal = ({ isLoading }) => {
  if (!isLoading) return null;
  return createPortal(
    <LoadingSpinner />,
    document.getElementById('root') as HTMLElement,
  );
};

export default LoadingPortal;

export async function getClientIP() {
  try {
    const response = await fetch('https://api.ipify.org?format=json'); // 무료 IP 제공 API
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error('IP 가져오기 실패:', error);
    return null;
  }
}

import { useParams, useSearchParams } from 'react-router-dom';
import useAraLoad from '../hooks/useAraLoad';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { pageState } from '../store/pageState';
import { FormProvider, useForm } from 'react-hook-form';
import useAutoTest from '../hooks/useAutoTest';
import auotFill from '../library/autoFill';
import useHandleSubmit from '../hooks/useHandleSubmit';
import ProgressBar from '../components/FormPage/modules/ProgressBar';
import Chunk from '../components/FormPage/modules/Chunk';
import FormHeader from '../components/FormPage/modules/FormHeader';
import FooterBtnArea from '../components/shared/modules/button/FooterBtnArea';
import LoadingPortal from '../components/shared/templates/layouts/LoadingPortal';
import useColorThema from '../hooks/useColorThema';
import Footer from '../components/shared/atoms/layouts/Footer';

const FormPage = () => {
  const { serviceKey } = useParams();

  const [araPageData, setAraPageData] = useState<Array<Array<any>> | null>(
    null,
  );
  const [configData, setConfigData] = useState<any>(null);
  const [pageIdx] = useRecoilState(pageState);
  const { fetchAraData, fetchConfigData } = useAraLoad(serviceKey as string);

  useEffect(() => {
    if (!serviceKey) return;
    getData();
  }, [serviceKey]);

  const getData = async () => {
    if (serviceKey) {
      await fetchConfigData(setConfigData);
      await fetchAraData(setAraPageData);
    }
  };

  const methods = useForm();
  const { submitFunc, errorFunc } = useHandleSubmit(
    araPageData || [[]],
    configData as any,
  );

  useAutoTest(() => {
    auotFill(methods);
  });

  useEffect(() => {
    if (configData) {
      document.title = configData.serviceName;
    }
  }, [configData]);

  const thema = useColorThema(configData);

  if (!configData || !araPageData) return <LoadingPortal isLoading={true} />;

  return (
    <div className={`thema_${thema}`}>
      {araPageData.length > 1 && (
        <ProgressBar pageIdx={pageIdx} pageLength={araPageData.length} />
      )}
      <div className="user_wrap">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(submitFunc, errorFunc)}>
            <main>
              <div className="user_container">
                <div className="core">
                  <div className="form editor_area">
                    {pageIdx === 0 && configData && (
                      <FormHeader configData={configData} />
                    )}
                    <div className="form_cont">
                      {araPageData[pageIdx] &&
                        araPageData[pageIdx].map((item, idx) => {
                          return <Chunk item={item} key={idx} />;
                        })}
                    </div>
                  </div>
                </div>
                <FooterBtnArea
                  pageLength={araPageData.length}
                  configData={configData}
                  isForm={true}
                />
              </div>
            </main>
          </form>
        </FormProvider>
        <Footer />
      </div>
    </div>
  );
};

export default FormPage;

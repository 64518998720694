import classNames from 'classnames';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

const ApplyCheckEmail = () => {
  const { register } = useFormContext();
  const [isFocus, setIsFocus] = useState(false);

  return (
    <div className="form_cell">
      <div className="form_cell_header">
        <p className="title active">이메일</p>
      </div>
      <div className="form_cell_cont">
        <div className="cell_box">
          <div className={classNames({ cell_txt1: true, focus: isFocus })}>
            <input
              type="text"
              {...register('email')}
              placeholder={`이메일을 입력바랍니다.`}
              onFocus={() => setIsFocus(true)}
              onBlur={() => setIsFocus(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyCheckEmail;

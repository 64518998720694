import classNames from 'classnames';
import { DatabaseDataType } from './DatabaseTable';
import { useFormContext } from 'react-hook-form';
import { useSectionContext } from '../../../../contexts/SectionContext';
import RadioCheckedM from '../../../shared/atoms/svgs/RadioCheckedM';
import CheckboxCheckedM from '../../../shared/atoms/svgs/CheckboxCheckedM';

const DatabaseSelect = props => {
  const databaseData = props.actionData as DatabaseDataType;
  const { idx, isMultiple, isRanked, radioLimit, rankedCount } = props;
  const thisName = isRanked
    ? `rankedSelect_${idx}`
    : isMultiple
    ? `databaseCheckBox_${idx}`
    : `databaseRadio_${idx}`;
  const { register, watch, getValues } = useFormContext();
  const { required, isStatic } = useSectionContext();

  watch(thisName);

  if (isStatic) {
    let thisValue = getValues(thisName);
    if (!thisValue) return null;
    if (isMultiple) {
      const parsedValue = thisValue.map(value => JSON.parse(value));
      return (
        <p className="result">
          {parsedValue.map(value => value.option).join(', ')}
        </p>
      );
    } else {
      const parsedValue = JSON.parse(thisValue);
      return <p className="result">{parsedValue.option}</p>;
    }
  }

  return (
    <div className="cell_box">
      {databaseData.body.map((item, index) => {
        return (
          <div
            className={classNames({
              cell_check2: isMultiple,
              cell_radio2: !isMultiple,
            })}
            key={`db_ck_${props.idx}_${index}`}
          >
            <input
              type={isMultiple ? 'checkbox' : 'radio'}
              id={`db_ck_${props.idx}_${index}`}
              value={JSON.stringify({
                index,
                option: Object.values(item).join('|'),
              })}
              {...register(thisName, {
                required: required,
              })}
              disabled={
                rankedCount
                  ? rankedCount[index].CurrentCount >=
                    rankedCount[index].MaxCapacity
                  : false
              }
            />
            <label htmlFor={`db_ck_${props.idx}_${index}`}>
              <Checked
                isMultiple={isMultiple}
                value={getValues(thisName)}
                item={item}
              />
              {databaseData.header.map(
                header =>
                  !header.hidden && (
                    <span
                      key={header.headerIdx}
                      dangerouslySetInnerHTML={{
                        __html: item[header.headerIdx],
                      }}
                    />
                  ),
              )}
              {isRanked && (
                <p className="order_arrival">
                  <em className="now">
                    현재{' '}
                    <strong>
                      {rankedCount ? rankedCount[index]?.CurrentCount : '0'}
                    </strong>
                    명
                  </em>
                  <em>
                    총{' '}
                    <strong>
                      {rankedCount ? rankedCount[index]?.MaxCapacity : '-'}
                    </strong>
                    명
                  </em>
                </p>
              )}
            </label>
          </div>
        );
      })}
    </div>
  );
};

const Checked = ({ isMultiple, value, item }) => {
  const itemValue = Object.values(item).join('|');

  if (isMultiple) {
    return (
      (value && value.some(ele => JSON.parse(ele).option === itemValue) && (
        <CheckboxCheckedM />
      )) ||
      null
    );
  } else {
    return (
      (value && JSON.parse(value).option === itemValue && <RadioCheckedM />) ||
      null
    );
  }
};

export default DatabaseSelect;
